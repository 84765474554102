import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const IndexPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Family Farming" 
      subtitle="We're just families whose business is farming" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />

    <div className="l-primary">
      <nav>
        <h3>Fundamentals</h3>
        <ul>
          <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
          <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
          <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
          <li>
              <Link to={'/fundamentals/what-we-grow'}>What We Grow</Link>
              <ul>
                <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
              </ul>   
          </li>
          <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
        </ul> 
      </nav>
      <section>
        <p>According to the USDA’s latest Census of Agriculture in 2017, there are 2,042,220 farms in the United States. The USDA definition of a farm is misleading as they include individuals with as little as $1,000 in agricultural product sales in this total. In contrast, the same Census tells us that large and very large farms together accounted for only 3.9% of all US farms, however, this group produced over 68% of all agricultural product sales. Howell Farms of Crossroads is one of only 8,972 farms in the country, 0.4% of the total, which produced 34% of the value of all US agricultural production.</p>
        <blockquote>We are just families whose business happens to be farming. We like to grow things ‐ and we are privileged to work together to grow them!</blockquote>
        <p>Economics dictates that the agriculture industry recognize and implement the efficiency and increased productivity of large scale operations – just as other industries such as manufacturing and retail have done. Yet, despite the trend toward consolidation of farms, 97% of all the farms in the US are still family owned and operated. Even in our “largest” category, 87% are owned by families. We are just families whose business happens to be farming. We like to grow things ‐ and we are privileged to work together to grow them!</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "family-farming-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
